<template>
  <div id="employees" class="contenedor">
    <loader :show="show" />
    <sesion :expiration="expiration" />
    <Sidebar></Sidebar>
    <v-container fluid class="base text-center">
      <v-row no-gutters class="titulo ml-10 mt-5">ROLES DE USUARIO</v-row>
      <v-row no-gutters justify="center" class="mr-12 mb-4">
        <v-col
          cols="12"
          lg="6"
          md="6"
          sm="12"
          xs="12"
          class="selectEmpresa"
          align-self="end"
        >
          <v-row class="ml-12">
            <label for="enterprise" class="ml-5 mr-2">Empresa Pagadora</label>
            <select
              name="enterprise"
              id="enterprise"
              v-model="enterprise"
              @change="obtenerEmpresa()"
            >
              <option selected disabled value="0">Selecciona Empresa</option>
              <option
                v-for="enterprise in enterprises"
                :key="enterprise.id"
                v-bind:value="enterprise.id"
              >
                {{ enterprise.razonSocial }}
              </option>
            </select>
          </v-row>
        </v-col>
        <v-col cols="12" lg="6" md="6" sm="12" xs="12">
          <v-card-title>
            <v-text-field
              class="mr-2"
              v-model="search"
              append-icon="search"
              label="Buscador"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
        </v-col>
      </v-row>
      <v-row no-gutters class="datos pa-0" style="height: auto !important">
        <v-card style="width: 100%; background: rgb(199, 195, 199) !important">
          <v-data-table
            :headers="headers"
            :items="employees"
            :search="search"
            class="elevation-2 text--dark"
            loading-text="Cargando datos... Por favor espere"
            :items-per-page="5"
            :no-results-text="'No se encontraron incidencias'"
            :no-data-text="'No hay datos'"
            :footer-props="{
              'items-per-page-text': 'Empleados por página',
              'items-per-page': [5, 10, 15],
              'items-per-page-all-text': 'Todos',
              'items-per-page-options': [5],
              'loading-text': 'Obteniendo datos... Por favor espere',
              'no-data-text': 'No hay datos...',
              'no-results-text': 'No se encontraron incidencias',
            }"
          >
            <template v-slot:no-data>
              <v-alert :value="true" color="#ffffff" icon="warning">
                <p style="color: black">No hay datos en sistema ...</p>
              </v-alert>
            </template>
            <template v-slot:body="{ items }">
              <tbody>
                <tr
                  v-for="item in items"
                  :key="item.id"
                  :class="{ selectedRow: item === selectedItem }"
                >
                  <td class="text-sm-center">{{ item.empleadoId }}</td>
                  <td class="text-sm-center">{{ item.nombreEmpleado }}</td>
                  <td class="justify-center layout px-0">
                    <v-icon color="#717171" small @click="viewItem(item)"
                      >fa-edit</v-icon
                    >
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-card>
      </v-row>
    </v-container>
    <!--Aceptación de acciones-->
    <v-dialog v-model="advice" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Confirmación</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 style="text-align: center">{{ respuesta }}</h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="advice = false">Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Confirmación de datos-->
    <v-dialog v-model="confirmation" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Asignación de Rol</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-lg-center text-sm-center text-md-center">
            {{ respuesta }}
          </h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="red"
            text
            @click="
              confirmation = false;
              rol = 0;
            "
            >Cancelar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="asignacion()">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--DIALOG DE DATOS-->
    <v-dialog v-model="dialog" max-width="340px">
      <v-card>
        <v-card-title class="orange white--text">Rol de Usuario</v-card-title>

        <v-card-text>
          <!--EMPRESA-->
          <v-row>
            <div class="form-group mt-5 mb-2">
              <label for="empleado" class="ml-2 mr-12">Empleado</label>
              <select
                name="empleado"
                id="empleado"
                class="ml-1 mt-n6"
                style="width: 170px !important"
                disabled
              >
                <option selected>
                  {{ empleado.nombreEmpleado }}
                </option>
              </select>
            </div>
          </v-row>
          <!--ROL-->
          <v-row>
            <div class="form-group">
              <label for="enterprise" class="ml-2 mr-1">Rol asignado</label>
              <select
                name="enterprise"
                id="enterprise"
                class="ml-8 mt-n6"
                style="width: 170px !important"
                v-model="rol"
              >
                <option selected disabled value="0">Seleccione rol</option>
                <option value="Basico">Básico</option>
                <option value="Medio">Medio</option>
                <option value="Admin">Administrador</option>
              </select>
            </div>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="red"
            text
            @click="
              dialog = false;
              rol = 0;
            "
            >Cancelar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="asignar()">Asignar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Loader from "@/components/Loader";
import Sesion from "@/components/Sesion";
export default {
  components: {
    Sidebar,
    Loader,
    Sesion,
  },
  data() {
    return {
      show: false,
      expiration: false,
      search: "",
      dialog: false,
      selectedItem: null,
      headers: [
        {
          text: "Id Empleado",
          align: "center",
          value: "empleadoId",
        },
        {
          text: "Nombre Empleado",
          align: "center",
          value: "nombreEmpleado",
        },
        {
          text: "Rol",
          value: "actions",
          align: "center",
          sortable: false,
        },
      ],
      employees: [],
      employeeNumber: 0,
      enterprise: localStorage.empresaIdGlobal,
      enterprises: [],
      respuesta: "",
      advice: false,
      id: "",
      confirmation: false,
      empleado: {},
      rol: 0,
      mensaje: "Favor de asignar un rol al usuario",
    };
  },
  methods: {
    asignacion() {
      this.advice = false;
      this.show = true;
      axios
        .post(
          Server + "/usuarios/role/asignar",
          {
            UserId: this.empleado.email,
            RoleName: this.rol,
          },
          {
            headers: {
              Authorization: localStorage.token,
            },
          }
        )
        .then((response) => {
          this.rol = 0;
          this.nombre = {};
          this.respuesta = "El rol fue asignado correctamente.";
          this.show = false;
          this.confirmation =false;
          this.advice = true;
        })
        .catch((e) => {
          this.show = false;
          this.rol = 0;
          this.nombre = {};
          this.respuesta = "El rol no fue asignado.";
          this.confirmation = false;
          this.advice = true;
        });
    },
    asignar() {
      this.dialog = false;
      if (this.rol == 0) this.error = true;
      else {
        this.respuesta =
          "¿Esta seguro de asignar el rol de " +
          this.rol +
          " al usuario " +
          this.empleado.nombreEmpleado +
          " ?";
        this.confirmation = true;
      }
    },
    viewItem(item) {
      this.empleado = item;
      console.log(this.empleado);
      this.dialog = true;
    },
    obtenerEmpresa() {
      this.employees = [];
      this.show = true;
      axios
        .get(Server + "/usuarios/role/empleados/" + this.enterprise, {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          response.data.forEach((usuario) => {
            if (usuario.email == null || usuario.email == undefined) {
              //console.log("lo descarto");
            } else {
              this.employees.push(usuario);
            }
          });
          this.show = false;
        })
        .catch((e) => {
          console.log("Error", e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    listar() {
      this.show = true;
      axios
        .get(Server + "/empresas", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log(response);
          this.enterprises = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
  },
  created() {
    this.listar();
    this.obtenerEmpresa();
  },
};
</script>
